// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import "./controllers";
import "justified-layout"
import { Fancybox } from "@fancyapps/ui";

import "trix"
import "@rails/actiontext"

document.addEventListener("turbo:load", function() {
    let options = {
        Toolbar: {
          display: {
            left: ["infobar"],
            middle: [],
            right: ["slideshow", "download", "thumbs", "close"],
          },
        },
      };

    Fancybox.bind('[data-fancybox]', options);
    console.log('hi');
});
